@import "styles.module.scss";

.app-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
}

.app {
    flex-grow: 1;
}

.content {
    flex-grow: 1;
    display: block;
    overflow: auto;
}

.app-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
}

.app-logo {
    color: rgba(255, 255, 255, 0.9) !important;
    margin: 0 !important;
    font-size: 26px !important;
}

.logout {
    color: $error-color !important;
}
