$primary-color: #1890ff; // primary color for all components
$link-color: #1890ff; // link color
$success-color: #52c41a; // success state color
$warning-color: #faad14; // warning state color
$error-color: #f5222d; // error state color
$font-size-base: 14px; // major text font size
$heading-color: rgba(0, 0, 0, 0.85); // heading text color
$text-color: rgba(0, 0, 0, 0.65); // major text color
$text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
$disabled-color: rgba(0, 0, 0, 0.25); // disable state color
$border-radius-base: 4px; // major border radius
$border-color-base: #d9d9d9; // major border color
$box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15); // major shadow for layers

.center-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.center-container-stretch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}

.flex-grower {
    flex-grow: 1;
}

.cardLogoWrapper {
    text-align: center;
    padding: 3%;
}

//overide the tail of timeline to make it visible
[class*='ant-timeline-item-tail'] {
    border-color: #B5B2B0;
   
}

@media screen and (max-width:769px) {
    .center-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10%;
    }
}